define("discourse/plugins/discourse-solved/discourse/connectors/after-topic-status/solved-status", ["exports", "truth-helpers", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _dIcon, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SolvedStatus = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{~#if
      (or @outletArgs.topic.has_accepted_answer @outletArgs.topic.accepted_answer)
    ~}}
      <span
        title={{i18n "topic_statuses.solved.help"}}
        class="topic-status solved"
      >{{icon "far-square-check"}}</span>
    {{~else if
      (and
        @outletArgs.topic.can_have_answer (eq @outletArgs.context "topic-list")
      )
    ~}}
      <span
        title={{i18n "solved.has_no_accepted_answer"}}
        class="topic-status"
      >{{icon "far-square"}}</span>
    {{~/if~}}
  
  */
  {
    "id": "ACmr+cnc",
    "block": "[[[41,[28,[32,0],[[30,1,[\"topic\",\"has_accepted_answer\"]],[30,1,[\"topic\",\"accepted_answer\"]]],null],[[[10,1],[15,\"title\",[28,[32,1],[\"topic_statuses.solved.help\"],null]],[14,0,\"topic-status solved\"],[12],[1,[28,[32,2],[\"far-square-check\"],null]],[13]],[]],[[[41,[28,[32,3],[[30,1,[\"topic\",\"can_have_answer\"]],[28,[32,4],[[30,1,[\"context\"]],\"topic-list\"],null]],null],[[[10,1],[15,\"title\",[28,[32,1],[\"solved.has_no_accepted_answer\"],null]],[14,0,\"topic-status\"],[12],[1,[28,[32,2],[\"far-square\"],null]],[13]],[]],null]],[]]]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-solved/discourse/connectors/after-topic-status/solved-status.js",
    "scope": () => [_truthHelpers.or, _discourseI18n.i18n, _dIcon.default, _truthHelpers.and, _truthHelpers.eq],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "solved-status:SolvedStatus"));
  var _default = _exports.default = SolvedStatus;
});